<template>
  <div>
    <q-form ref="editForm">
      <c-card title="LBLBASEINFO" class="cardClassDetailForm">
        <template slot="card-button">
          <q-btn-group outline >
            <c-btn
              v-show="editable"
              :url="saveUrl"
              :isSubmit="isSave"
              :param="chem"
              :mappingType="mappingType"
              label="LBLSAVE"
              icon="save"
              @beforeAction="saveInfo"
              @btnCallback="saveCallback" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <c-text
              :required="true"
              :disabled="disabled"
              :editable="editable"
              label="화학물질명(ENG)"
              name="chemNmEn"
              v-model="chem.chemNmEn">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <c-text
              :disabled="disabled"
              :editable="editable"
              label="화학물질명(KOR)"
              name="chemNmKr"
              v-model="chem.chemNmKr">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
            <c-text
              :required="true"
              :disabled="disabled"
              :editable="editable"
              label="CAS No."
              name="casNo"
              v-model="chem.casNo">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
            <c-text
              :editable="editable"
              label="화학식"
              name="molecularFormula"
              v-model="chem.molecularFormula">
            </c-text>
          </div>
          <!-- <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
            <c-checkbox
              :editable="editable"
              :isFlag="true"
              label="규제물질 대상여부"
              name="regulFlag"
              v-model="chem.regulFlag"
            />
          </div> -->
        </template>
      </c-card>
    </q-form>
    <c-tab
      class="q-mt-sm"
      type="tabcard"
      align="left"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      :dense="true"
      :height="tabHeight"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :chem.sync="chem"
          :contentHeight="contentHeight"
        />
      </template>
    </c-tab>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'chemBaseDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        mdmChemId: '',
      }),
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      chem: {
        mdmChemId: '',  // 화학물질 일련번호
        casNo: '',  // CAS No.
        chemNmEn: '',  // 화학물질명(ENG)
        chemNmKr: '',  // 화학물질명(KOR)
        nlId: '',  // 뉴로라인즈 물질ID
        molecularFormula: '',  // 화학식
        regulFlag: 'N',  // 규제물질 대상여부
        nlRegDt: '',  // 뉴로라인즈 생성일
        nlChgDt: '',  // 뉴로라인즈 수정일
        mttrid: '',  // 산업안전보건공단 chemid
        deleteFlag: '',  // 삭제여부
        regUserId: '',  // 등록자
        chgUserId: '',  // 수정자
        // 상세정보
        boilingPoint: '',  // 끓는점-위험성평가용
        cmrFlag: 'N',  // CMR 물질 여부-위험성평가용
        carcinogens: '',  // 발암성물질
        mutagens: '',  // 변이원성물질
        reproductiveToxins: '',  // 생식독성물질
        maleficenceForm: null,  // 유해성 발생형태-위험성평가용
        maleficenceValue: null,  // 유해성 측정값-위험성평가용
        maleficenceGrade: null,  // 비산성 등급-위험성평가용
        maleficenceType: null,  // 비산/휘발성 구분-위험성평가용
        hrCodeGrade: null,  // 위험문구/유해위험문구 등급-위험성평가용
        hcode: '',  // 유해･위험문구-위험성평가용
        rcode: '',  // 위험문구-위험성평가용
        restrictions: '',  // 제한사항
        prohibited: '',  // 금지사항
        accidentManuQuantity: '',  // 사고대비물질_제조사용 지정수량(Kg)
        accidentStoreQuantity: '',  // 사고대비물질_보관저장 지정수량(kg)
        exclusionConditions: '',  // 제외조건내용
        exclusionConditionSign: null,  // 제외조건_부등호
        exclusionConditionQuantity: '',  // 제외조건_정량값
        exclusionConditionUnit: '',  // 제외조건_단위
        prtrGroupId: '',  // PRTR_Group_ID
        prtrMaterialName: '',  // PRTR_물질명
        prtrSwcsWt: '',  // PRTR_조사대상무게함유기준(wt%)
        prtrSwcsWtSign: null,  // PRTR_조사대상무게함유기준_부등호
        prtrSwcsWtQuantity: null,  // PRTR_조사대상무게함유기준_정량값
        prtrSwcsWtUnit: '',  // PRTR_조사대상무게함유기준_단위
        prtrSwcsTon: '',  // PRTR_조사대상취급량기준(톤/년)
        prtrSwcsTonSign: '',  // PRTR_조사대상취급량기준_부등호
        prtrSwcsTonQuantity: '',  // PRTR_조사대상취급량기준_정량값
        prtrSwcsTonUnit: '',  // PRTR_조사대상취급량기준_단위
        remark1: '',  // 고시내용 외 참조내용 입력(예, CMR, PBT 등)
        dangerousContents: '',  // 위험물내용
        dangerousClass: '',  // 위험물유별구분
        dangerousProp: '',  // 위험물성질
        dangerousName: '',  // 위험물품명
        designatedQuantityDose: '',  // 지정수량_정량값
        designatedQuantityUnit: '',  // 지정수량_단위
        psmAmount: '',  // 공정안전관리 제조∙취급∙저장량(kg/일)
        bei: '',  // 생물학적노출기준(단위포함 Full text)
        sensitization: '',  // 과민성 여부 입력(고용노동부, ACGIH 등)
        simpleAsphyxiants: '',  // 질식제 여부 입력(ACGIH, EU 노출기준 등)
        skinNotations: '',  // 피부영향 여부 입력(고용노동부, ACGIH 등)
        basisCriticalEffects: '',  // 표적장기 입력(예, 간, 신장 등)
        twaPpm: '',  // TWA 노출기준입력 ppm
        twaMm: '',  // TWA 노출기준입력 mg/m3
        stelPpm: '',  // STEL 노출기준입력 ppm
        stelMm: '',  // STEL 노출기준입력 mg/m3
        ceilingsPpm: '',  // Celling 노출기준입력 ppm
        ceilingsMm: '',  // Celling 노출기준입력 mg/m3
        lelPpm: '',  // LEL노출기준입력 ppm
        lelMm: '',  // LEL노출기준입력 mg/m3
        remark2: '',  // 적용기준, 고시내용 외 추가 참조사항 입력
        remark3: '',  // 기타 참조사항
        reguls: [], // 평가자
        deleteReguls: [], // [삭제]평가자
        columnSetting: {}
      },
      tab: 'regul',
      tabItems: [
        { name: 'regul', icon: 'schema', label: '규제', component: () => import(`${'./chemBaseRegul.vue'}`) },
        { name: 'detail', icon: 'settings_suggest', label: '화학물질 상세정보', component: () => import(`${'./chemBaseDetailInfo.vue'}`) },
      ],
      editable: true,
      isSave: false,
      detailUrl: '',
      detailColumnSettingUrl: '',
      validUrl: '',
      saveUrl: '',
      mappingType: 'PUT',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    isOld() {
      return Boolean(this.popupParam.mdmChemId);
    },
    disabled() {
      /**
       * 뉴로라인즈에서 받는 정보인 경우 다음 항목 비활성화
       * 
       *  CAS No. (규제 DB 업로드 시에 키처럼 비교함으로)
       * 명칭 (뉴로라인즈에서 키처럼 사용 중임으로)
       */
      return Boolean(this.chem.nlId);
    },
    tabHeight() {
      return this.contentHeight ? String(this.contentHeight - 250) + 'px' : '500px'
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.chm.base.chemBase.get.url
      this.detailColumnSettingUrl = selectConfig.chm.base.chemBaseColumnSetting.get.url;
      this.validUrl = selectConfig.chm.base.chemBase.valid.url
      this.saveUrl = transactionConfig.chm.base.chemBase.update.url
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.isOld) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.mdmChemId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.chem, _result.data)
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
          for (let key in this.chem) {
            this.$set(this.chem, `${key}Disabled`, (this.chem.columnSetting[key] === 'Y' ? true : false))
          }
        },);
      } else {
        // column setting info get
        this.$http.url = this.detailColumnSettingUrl;
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.chem.columnSetting, _result.data)
          for (let key in this.chem) {
            this.$set(this.chem, `${key}Disabled`, (this.chem.columnSetting[key] === 'Y' ? true : false))
          }
        },);
      }
    },
    saveInfo() {
      if (this.isOld) {
        this.saveUrl = transactionConfig.chm.base.chemBase.update.url
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.chm.base.chemBase.insert.url
        this.mappingType = 'POST';
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          this.$http.url = this.validUrl
          this.$http.type = 'GET';
          this.$http.param = {
            mdmChemId: this.popupParam.mdmChemId,
            casNo: this.chem.casNo
          }
          this.$http.request((_result) => {
            if (_result.data === 0) {
              window.getApp.$emit('CONFIRM', {
                title: 'LBLCONFIRM',
                message: 'MSGSAVE', // 저장하시겠습니까?
                // TODO : 필요시 추가하세요.
                type: 'info', // success / info / warning / error
                // 확인 callback 함수
                confirmCallback: () => {
                  this.chem.regUserId = this.$store.getters.user.userId;
                  this.chem.chgUserId = this.$store.getters.user.userId;

                  this.isSave = !this.isSave;
                },
                // 취소 callback 함수
                cancelCallback: () => {
                },
              });
            } else {
              window.getApp.$emit('ALERT', {
                title: 'LBLGUIDE', // 안내
                message: '입력한 CAS No.를 가진 화학물질이 이미 등록되어있습니다.', // 입력한 CAS No.를 가진 화학물질이 이미 등록되어있습니다.
                type: 'warning', // success / info / warning / error
              });
            }
          },);
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$set(this.popupParam, 'mdmChemId', result.data)
      this.getDetail();
    },
  }
};
</script>